html {
  overflow-y: scroll !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-y;
  background-color: rgb(249, 250, 250);
}

*:not(.enable-scrollbar)::-webkit-scrollbar {
  display: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* icon */
i.material-icons,
span.material-icons,
svg.MuiSvgIcon-root {
  font-size: 18px;

  @media (min-width: 1920px) {
    font-size: 20px;
  }
}

.textInput4 {
  width: 32%;
  margin: 0.6% !important;
  float: left;
}

.clearfix {
  clear: both;
}

.datagrid-wrapper {
  overflow: hidden;
  max-height: calc(100vh - 200px);
  display: flex;

  @media (max-width: 959px) {
    max-height: calc(100vh - 204px);
  }

  @media (max-width: 599px) {
    max-height: calc(100vh - 184px);
  }
}

.list-page:has(.visual-container) .datagrid-wrapper {
  max-height: calc(100vh - 210px);
}

.datagrid-wrapper thead {
  position: sticky;
  top: 0px;
  z-index: 2;
}

.datagrid-wrapper .group-table tr.hovered {
  background-color: rgba(0, 0, 0, 0.04);
}

.datagrid-wrapper .reordering-columns-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 45px;
  left: 0px;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.04);
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(51, 60, 68);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.datagrid-wrapper .empty-table {
  width: 100%;
  position: absolute;
  top: 45px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-size: 1rem;
  color: rgb(51, 60, 68);
  padding-top: 64px;
  padding-bottom: 64px;
  box-shadow: rgba(25, 39, 52, 0.05) 0px 1px 2px 0px, rgba(25, 39, 52, 0.1) 0px 0px 4px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.datagrid-wrapper .empty-table .reordering-columns-overlay {
  top: 0px;
}

.datagrid-wrapper.reordering .empty-table {
  color: rgba(0, 0, 0, 0.04);
}

.datagrid-wrapper button.row-menu-button {
  border-radius: 50%;
  background-color: white;
  border: 1px solid rgb(230, 230, 230);
}

.datagrid-wrapper button.row-menu-button:hover {
  background-color: rgb(230, 230, 230);
}

.datagrid-wrapper.block-scroll .group-table {
  overflow-y: hidden !important;
}

.datagrid-wrapper .group-table:not(.pinned-table).has-row-menu tr:hover td:has(button.row-menu-button),
.datagrid-wrapper .group-table:not(.pinned-table).has-row-menu tr td:has(button.row-menu-button.selected) {
  position: sticky;
  right: 0px;
  background: transparent;
}


.datagrid-wrapper .group-table:not(.pinned-table).has-row-menu tr td button.row-menu-button.selected {
  background-color: rgba(0, 0, 0, 0.04);
}

.datagrid-wrapper .MuiTableCell-root .MuiTableSortLabel-icon {
  display: inline;
  visibility: hidden;
  border-radius: 50%;
  width: calc(1em + 8px);
  height: calc(1em + 8px);
  padding: 4px;
}

.datagrid-wrapper .MuiTableCell-root .MuiTableSortLabel-icon {
  visibility: hidden;
}

.datagrid-wrapper .MuiTableCell-root .Mui-active .MuiTableSortLabel-icon {
  visibility: visible;
}

.datagrid-wrapper .MuiTableCell-root .MuiTableSortLabel-icon:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.datagrid-wrapper .MuiTableCell-root .table-header-cell-sort-button,
.datagrid-wrapper .MuiTableCell-root .table-header-cell-options-button {
  visibility: hidden;
  padding: 4px;
}

.datagrid-wrapper .MuiTableCell-root:hover .MuiTableSortLabel-icon {
  visibility: visible;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.8;
}

.datagrid-wrapper .group-table.pinned-table {
  overflow-y: auto;
  width: fit-content;
  z-index: 3;
}

.datagrid-wrapper .group-table.pinned-table.divider {
  box-shadow: rgba(25, 39, 52, 0.05) 4px 0px 2px -2px;
  border-right: 1px solid rgba(25, 39, 52, 0.05)
}

.datagrid-wrapper .group-table:not(.pinned-table) {
  overflow: auto;
  width: 1px;
  flex-grow: 1;
}

.datagrid-wrapper.reordering table tbody {
  visibility: hidden;
}

.datagrid-wrapper.reordering .group-table.pinned-table.divider {
  box-shadow: none;
  border-right: none;
}

.datagrid-wrapper.reordering .reordering-columns-overlay {
  display: flex
}

.datagrid-wrapper table img {
  height: 38px !important;
  width: 38px !important;
  margin: 0px !important;
  padding: 0px !important;
}

.datagrid-wrapper.reordering .group-table.pinned-table table thead {
  box-shadow: rgba(25, 39, 52, 0.05) 4px 0px 2px -2px;
  border-right: 1px solid rgba(25, 39, 52, 0.05);
}

.datagrid-wrapper .MuiTableCell-root:hover .table-header-cell-sort-button,
.datagrid-wrapper .MuiTableCell-root .table-header-cell-sort-button.active,
.datagrid-wrapper .MuiTableCell-root:hover .table-header-cell-options-button {
  visibility: visible;
}

.list-page {
  height: 100%;
}

.appMainContent:has(.list-page #list-mobile-actions) {
  padding-bottom: 48px;
  padding-top: 8px;

  .datagrid-wrapper {
    @media (max-width: 959px) {
      max-height: calc(100vh - 194px);
    }

    @media (max-width: 599px) {
      max-height: calc(100vh - 192px);
    }
  }
}

.appMainContent:has(.show-page #show-mobile-actions) {
  padding-bottom: 32px;

  @media (max-width: 599px) {
    padding-bottom: 48px;

  }
}

.datagrid-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.date-range-input {
  input {
    min-width: 140px;
  }
}

.tui-editor-viewer {
  border: 0.5px solid #0D0D0D;
  background: #f9fafa;
  color: #000000;
  font-family: 'JetBrainsMono', monospace;
  border-radius: 0.5rem;
  padding: 8px;
}

.slick-slider .slick-dots {
  bottom: -3px;
}

.tui-editor-viewer .tui-editor-contents {
  display: inherit !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  p:first-child {
    margin-top: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.column-content {
  .tui-editor-viewer .tui-editor-contents {
    display: inherit !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 17px;
  }
}

table {
  .tui-editor-contents {
    display: -webkit-box;
  }
}

.table-image-field img {
  width: 70%;
  max-height: 100%;
  object-fit: contain;
  aspect-ratio: 1;
}

.hidden-label-filter button.add-filter span:first-child>span {
  display: none;
}

.button-add-column {
  background-color: rgb(36, 144, 239) !important;
  color: white !important;
  position: fixed !important;
  top: auto;
  left: auto;
  color: #fff;
  right: 20px;
  bottom: 60px;
  margin: 0;
  z-index: 1000;

  @media (max-width: 960px) {
    bottom: 130px;
  }
}

.datagrid-wrapper {
  .column-ownerId {
    label {
      display: none !important;
    }
  }
}

.table-image-field,
.default-image-field {
  background-color: #e0e0e0;
  display: flex;
  flex-direction: column;
}

.table-image-field>img {
  height: 50px;
  width: 50px;
}

table tbody tr td .table-image-field {
  display: flex;
  align-items: center;
  justify-content: center;

  &>img {
    margin: 0;
  }
}

.ColorInput-popup {
  position: absolute;
  z-index: 2;
}

.ColorInput-cover {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.absolute-left-align {
  left: 0;
}

.absolute-right-align {
  right: 0;
}

.invisible {
  display: none;
}

.MuiButton-root {
  text-transform: capitalize !important;
}

.ReactVirtualized__Table__headerColumn {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pagination-actions-wrapper {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0px -8px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

div[data-rmiz-overlay] {
  background-color: rgba(255, 255, 255, 1) !important;
}

.show-more-text-container>span>span {
  position: 'inherit !important';
  visibility: 'visible !important';
}

.pac-container {
  z-index: 9999;
}

.register-form .MuiFormControl-root,
.register-form .ra-input.image-input,
.register-form .MuiFormGroup-root {
  margin: 8px 0 4px;
}

.register-form .MuiFormHelperText-root {
  padding-left: 0px;
  margin-left: 0px;
}

@font-face {
  font-family: HellixBold;
  src: url(./fonts/Hellix-Bold.ttf);
}

@font-face {
  font-family: HellixMedium;
  src: url(./fonts/Hellix-Medium.ttf);
}

@font-face {
  font-family: HellixRegular;
  src: url(./fonts/Hellix-Regular.ttf);
}

@font-face {
  font-family: HellixSemiBold;
  src: url(./fonts/Hellix-SemiBold.ttf);
}

@font-face {
  font-family: InterBold;
  src: url(./fonts/Inter-Bold.ttf);
}

@font-face {
  font-family: InterMedium;
  src: url(./fonts/Inter-Light.ttf);
}

@font-face {
  font-family: InterRegular;
  src: url(./fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: InterSemiBold;
  src: url(./fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: InterSemiBold;
  src: url(./fonts/Inter-SemiBold.ttf);
}

@font-face {
  font-family: WorkSansBold;
  src: url(./fonts/WorkSans-Bold.ttf);
}

@font-face {
  font-family: WorkSansLight;
  src: url(./fonts/WorkSans-Light.ttf);
}

@font-face {
  font-family: WorkSansMedium;
  src: url(./fonts/WorkSans-Medium.ttf);
}

@font-face {
  font-family: WorkSansRegular;
  src: url(./fonts/WorkSans-Regular.ttf);
}

@font-face {
  font-family: WorkSansSemiBold;
  src: url(./fonts/WorkSans-SemiBold.ttf);
}

@font-face {
  font-family: QuickSandBold;
  src: url(./fonts/Quicksand-Bold.ttf);
}

@font-face {
  font-family: QuickSandLight;
  src: url(./fonts/Quicksand-Light.ttf);
}

@font-face {
  font-family: QuickSandMedium;
  src: url(./fonts/Quicksand-Medium.ttf);
}

@font-face {
  font-family: QuickSandRegular;
  src: url(./fonts/Quicksand-Regular.ttf);
}

@font-face {
  font-family: QuickSandSemiBold;
  src: url(./fonts/Quicksand-SemiBold.ttf);
}