@import "./common/high-res-flags.scss";

.react-tel-input {
  .form-control {
    font-size: 16px;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    width: 300px;
    outline: none;
    padding: 18.5px 14px 18.5px 58px;
    transition: box-shadow ease .25s, border-color ease .25s;

    &:hover {
      border-color: #000;
    }

    &:focus {
      border-color: #1976d2;
      box-shadow: 0 0 0 1px #1976d2;

      &+div:before {
        color: #1976d2;
      }
    }

    &.invalid-number {
      border: 1px solid #f44336;

      &:focus {
        box-shadow: 0 0 0 1px #f44336;
      }

      &+div:before {
        content: 'Error';
        display: none;
        color: #f44336;
        width: 27px;
      }
    }
  }

  .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    border-radius: 3px 0 0 3px;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    &.open {
      z-index: 2;
    }
  }

  input[disabled] {
    &+.flag-dropdown {
      &:hover {
        cursor: default;
        border-color: #CACACA;

        .selected-flag {
          background-color: transparent;
        }
      }
    }
  }

  .selected-flag {
    outline: none;
    position: relative;
    width: 52px;
    height: 100%;
    padding: 0 0 0 11px;
    border-radius: 3px 0 0 3px;

    &:focus {
      .arrow {
        border-left-width: 4px;
        border-right-width: 4px;
        border-top: 5px solid #1976d2;
      }
    }

    .flag {
      position: absolute;
      top: 50%;
      margin-top: -12px;
    }

    .arrow {
      position: relative;
      top: 50%;
      margin-top: -1px;
      left: 29px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid #555;

      &.up {
        border-top: none;
        border-bottom: 4px solid #555;
      }
    }
  }

  .country-list {
    outline: none;
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 10px 0 10px -1px;
    box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
    background-color: white;
    width: 300px;
    max-height: 220px;
    overflow-y: scroll;
    border-radius: 7px;

    .flag {
      display: inline-block;
      position: absolute;
      left: 13px;
      top: 8px;
    }

    .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #ccc;
    }

    .country {
      position: relative;
      padding: 12px 9px 13px 46px;

      .dial-code {
        color: #6b6b6b;
      }

      &:hover {
        background-color: #f1f1f1;
      }

      &.highlight {
        background-color: #f1f1f1;
      }
    }

    .flag {
      margin-right: 7px;
      margin-top: 2px;
    }

    .country-name {
      margin-right: 6px;
    }

    .search {
      z-index: 2;
      position: sticky;
      top: 0;
      background-color: #fff;
      padding: 10px 0 6px 10px;
    }

    .search-emoji {
      display: none;
      font-size: 15px;
    }

    .search-box {
      border: 1px solid #cacaca;
      border-radius: 3px;
      font-size: 15px;
      line-height: 15px;
      margin-left: 6px;
      padding: 3px 8px 5px;
      outline: none;

      &:hover {
        border-color: #505050;
      }
    }

    .no-entries-message {
      padding: 7px 10px 11px;
      opacity: .7;
    }
  }

  .invalid-number-message {
    position: absolute;
    z-index: 1;
    font-size: 13px;
    left: 25px;
    top: -7px;
    background: #fff;
    padding: 0 5px;
    color: #de0000;
  }

  .special-label {
    position: absolute;
    z-index: 1;
    top: -7px;
    left: 25px;
    display: block;
    background: white;
    padding: 0 5px;
    font-size: 13px;
    white-space: nowrap;
  }
}